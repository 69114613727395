import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g id="YD" transform="translate(8.000000, 29.000000)">
          <path
            d="M0.865057 0.454545H8.09872L15.0639 13.6094H15.3622L22.3274 0.454545H29.5611L18.4197 20.2017V31H12.0064V20.2017L0.865057 0.454545ZM43.9165 31H33.0884V0.454545H44.006C47.0785 0.454545 49.7234 1.06605 51.9407 2.28906C54.158 3.50213 55.8633 5.24716 57.0565 7.52415C58.2596 9.80114 58.8612 12.5256 58.8612 15.6974C58.8612 18.8793 58.2596 21.6136 57.0565 23.9006C55.8633 26.1875 54.1481 27.9425 51.9109 29.1655C49.6836 30.3885 47.0188 31 43.9165 31ZM39.5465 25.4666H43.6481C45.5572 25.4666 47.163 25.1286 48.4656 24.4524C49.7781 23.7663 50.7624 22.7074 51.4187 21.2756C52.0849 19.8338 52.418 17.9744 52.418 15.6974C52.418 13.4403 52.0849 11.5959 51.4187 10.1641C50.7624 8.73224 49.783 7.67827 48.4805 7.00213C47.1779 6.32599 45.5721 5.98793 43.663 5.98793H39.5465V25.4666Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
